import firebase from "firebase";
import { db } from "../firebase";
import moment from "moment";
import { Letter } from "../types";
import { shuffleArray } from "./gameLogic";
import { wordsList } from "../data/WordList";

export async function deleteOutDatedPuzzles(): Promise<void> {
  firebase
    .database()
    .ref("/.info/serverTimeOffset")
    .once("value")
    .then(
      function stv(data) {
        const date = new Date(data.val() + Date.now());
        db.collection("wordDataByDate")
          .where(
            "date",
            "<",
            moment(date).subtract(7, "days").toDate().toISOString()
          )
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });
      },
      function (err) {
        return err;
      }
    )
    .catch((error) => {
      console.log(error);
    });
}

export async function getFirebaseWordData(
  date: string
): Promise<{ pll: Letter[][] | undefined; csl: Letter[] }> {
  return await db
    .collection("wordDataByDate")
    .doc(date)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return {
          pll: JSON.parse(doc.data()?.pll) as Letter[][],
          csl: JSON.parse(doc.data()?.csl) as Letter[],
        };
      } else {
        // create new word list, pll, and csl
        // creating word list
        const tempWords: string[] = [];
        for (let i = 0; i < 3; i++) {
          tempWords.push(
            wordsList[Math.floor(Math.random() * wordsList.length)]
          );
        }
        const tempProcessedLettersList: Letter[][] = [];
        const tempCurrentlySelectedLetters: Letter[] = [];
        // creating pll
        for (let i = 0; i < tempWords[0].length; i++) {
          tempProcessedLettersList.push([]);
        }
        for (let i = 0; i < tempWords[0].length; i++) {
          for (let j = 0; j < tempWords.length; j++) {
            let letterAlreadyInList = false;
            for (let k = 0; k < tempProcessedLettersList[i].length; k++) {
              if (tempProcessedLettersList[i][k].letter === tempWords[j][i]) {
                letterAlreadyInList = true;
              }
            }
            if (!letterAlreadyInList) {
              tempProcessedLettersList[i].push({
                letter: tempWords[j][i],
                isUsed: false,
              });
            }
          }
          tempCurrentlySelectedLetters.push(
            tempProcessedLettersList[i][
              Math.floor(
                Math.random() * (tempProcessedLettersList[i].length - 0)
              )
            ]
          );
          tempProcessedLettersList[i] = shuffleArray(
            tempProcessedLettersList[i]
          );
        }
        localStorage.setItem(
          "processedLettersList",
          JSON.stringify(tempProcessedLettersList)
        );
        localStorage.setItem(
          "currentlySelectedLetters",
          JSON.stringify(tempCurrentlySelectedLetters)
        );
        db.collection("wordDataByDate")
          .doc(date)
          .set({
            words: tempWords,
            pll: JSON.stringify(tempProcessedLettersList),
            csl: JSON.stringify(tempCurrentlySelectedLetters),
            date: new Date().toISOString(),
          });
        return {
          pll: tempProcessedLettersList,
          csl: tempCurrentlySelectedLetters,
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { pll: undefined, csl: [] };
    });
}
