import React, { ReactElement, useEffect, useState } from "react";
import "./CustomNotification.css";

type CustomNotificationProps = {
  message: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CustomNotification = (
  props: CustomNotificationProps
): ReactElement<CustomNotificationProps> => {
  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        props.setVisible(false);
      }, 3000);
    }
  }, [props.visible]);

  return (
    <div
      className={"notification-container" + (props.visible ? " visible" : "")}
    >
      <p>{props.message}</p>
    </div>
  );
};
