import { Letter } from "../types";

export const parseCSLfromLocalStorage = (): Letter[] => {
  const letters = JSON.parse(
    localStorage.getItem("currentlySelectedLetters") || "[]"
  );
  return letters;
};

export const parsePLLfromLocalStorage = (): Letter[][] => {
  const tempProcessedLettersList: Letter[][] = JSON.parse(
    localStorage.getItem("processedLettersList") || "[]"
  );
  return tempProcessedLettersList;
};
