import React, { ReactElement, useEffect, useState } from "react";
import "./LettersRoller.css";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import { parseCSLfromLocalStorage } from "../../utils/parseFromJSON";
import { Letter } from "../../types";
import useWindowDimensions from "../../utils/useWindowDimensions";

type LettersRollerProps = {
  letters: Letter[];
  index: number;
  currentlySelectedLetters: Letter[];
  changedCurrSelectedLetters: (letters: Letter[]) => void;
  setRemainingMoves: React.Dispatch<React.SetStateAction<number>>;
  remainingMoves: number;
  gameIsActive: boolean;
  retrieveFromLocalStorage: boolean;
};

const LettersRoller = (
  props: LettersRollerProps
): ReactElement<LettersRollerProps> => {
  const screenWidth = useWindowDimensions().width;

  const getIntialCSL = (): number => {
    const CSLfromLocalStorage = parseCSLfromLocalStorage();
    if (CSLfromLocalStorage.length > 0 && props.retrieveFromLocalStorage) {
      for (let i = 0; i < props.letters.length; i++) {
        if (
          CSLfromLocalStorage[props.index].letter === props.letters[i].letter
        ) {
          return i;
        }
      }
    }
    for (let i = 0; i < props.letters.length; i++) {
      if (
        props.letters[i].letter ===
        props.currentlySelectedLetters[props.index].letter
      ) {
        return i;
      }
    }
    console.log("Error while initializing CSL");
    return Math.floor(Math.random() * (props.letters.length - 0));
  };

  const [currentlySelectedIndex, setCurrentlySelectedIndex] = useState(
    getIntialCSL()
  );
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const gestureSensitivity = 75;

  useEffect(() => {
    if (props.currentlySelectedLetters.length > props.index) {
      props.currentlySelectedLetters[props.index] =
        props.letters[currentlySelectedIndex];
      props.changedCurrSelectedLetters(props.currentlySelectedLetters);
    }
  }, [currentlySelectedIndex]);

  const handleMouseStart = (e: any) => {
    setTouchStart(e.clientX);
  };
  const handleMouseMove = (e: any) => {
    setTouchEnd(e.clientX);
  };

  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleGestureEnd = () => {
    if (touchStart - touchEnd > gestureSensitivity) {
      rotateLetters("left");
    }
    if (touchStart - touchEnd < -gestureSensitivity) {
      rotateLetters("right");
    }
  };

  const rotateLetters = (direction: "left" | "right") => {
    if (props.gameIsActive) {
      if (direction === "left") {
        if (currentlySelectedIndex < props.letters.length - 1) {
          setCurrentlySelectedIndex(currentlySelectedIndex + 1);
          localStorage.setItem(
            "remainingMoves",
            String(props.remainingMoves - 1)
          );
          props.setRemainingMoves(
            Number(localStorage.getItem("remainingMoves"))
          );
        }
      } else if (direction === "right") {
        if (currentlySelectedIndex > 0) {
          setCurrentlySelectedIndex(currentlySelectedIndex - 1);
          localStorage.setItem(
            "remainingMoves",
            String(props.remainingMoves - 1)
          );
          props.setRemainingMoves(
            Number(localStorage.getItem("remainingMoves"))
          );
        }
      }
    }
  };

  const calcTranslateAmount = () => {
    let multiplier: number;
    if (screenWidth > 510) {
      multiplier = 70;
    } else if (screenWidth > 385) {
      multiplier = 58;
    } else if (screenWidth > 310) {
      multiplier = 46;
    } else {
      multiplier = 38;
    }
    return currentlySelectedIndex * multiplier;
  };

  return (
    <div
      className="letters-container"
      style={{ transform: `translateX(-${calcTranslateAmount()}px)` }}
      // onTouchStart={(e) => handleTouchStart(e)}
      // onTouchMove={(e) => handleTouchMove(e)}
      // onTouchEnd={() => handleGestureEnd()}
      // onMouseDown={(e) => handleMouseStart(e)}
      // onMouseMove={(e) => handleMouseMove(e)}
      // onMouseUp={() => handleGestureEnd()}
    >
      <Arrow
        className={
          "arrow-left" +
          (props.letters.length > 1 &&
          currentlySelectedIndex < props.letters.length - 1 &&
          props.gameIsActive
            ? ""
            : " hidden")
        }
        onClick={() => rotateLetters("left")}
      />
      {props.letters.map((letter, index) => {
        return (
          <div
            className={
              "letter" + (index === currentlySelectedIndex ? " selected" : "")
            }
            id={letter.isUsed ? "used" : ""}
            key={index}
          >
            {letter.letter.toUpperCase()}
          </div>
        );
      })}
      <Arrow
        className={
          "arrow-right" +
          (props.letters.length > 1 &&
          currentlySelectedIndex > 0 &&
          props.gameIsActive
            ? ""
            : " hidden")
        }
        onClick={() => rotateLetters("right")}
      />
    </div>
  );
};

export default LettersRoller;
