import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./CustomModal.css";
import { Letter, ModalType } from "../../types";

type CustomModalProps = {
  setGameIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<React.SetStateAction<string>>;
  remainingMoves: number;
  loading: boolean;
  type: ModalType;
  show: boolean;
};

const CustomModal = (
  props: CustomModalProps
): ReactElement<CustomModalProps> => {
  const [ticker, setTicker] = useState(0);
  const [copied, setCopied] = useState(false);
  const [clipboardText, setClipboardText] = useState("");
  const [percentScore, setPercentScore] = useState(0);

  useEffect(() => {
    let emojiString = "";
    let usedCount = 0;
    let totalCount = 0;
    const puzzleLayout = calculatePuzzleLayout();
    for (let col = 0; col < puzzleLayout.length; col++) {
      for (let row = 0; row < puzzleLayout[col].length; row++) {
        if (puzzleLayout[col][row] === true) {
          emojiString += "🟩";
          usedCount++;
          totalCount++;
        } else if (puzzleLayout[col][row] === false) {
          emojiString += "🟥";
          totalCount++;
        } else {
          emojiString += "⬛";
        }
      }
      emojiString += "\n";
    }
    const headerString =
      "Tileshift - " +
      moment().format("MMM Do") +
      "\nMoves: " +
      (30 - props.remainingMoves) +
      "\nTiles Used: " +
      usedCount +
      "/" +
      totalCount +
      "\n\n";
    setPercentScore(Math.floor((usedCount / totalCount) * 100));
    setClipboardText(headerString);
  }, [props.show, props.remainingMoves]);

  const getStats = (): ReactElement => {
    const gamesWon: number = JSON.parse(
      localStorage.getItem("gamesWon") || "[]"
    ).length;
    const streak: string[] = JSON.parse(
      localStorage.getItem("streaks") || "[]"
    ).length;
    return (
      <div className="modal-stats-container">
        <div className="modal-stat-block">
          <h2>{gamesWon}</h2>
          <p>Games Won</p>
        </div>
        <div className="modal-stat-block">
          <h2>{`${percentScore}%`}</h2>
          <p>Completion</p>
        </div>
        <div className="modal-stat-block">
          <h2>{streak}</h2>
          <p>Streak</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let interval: any = null;
    if (props.type === "win" || props.type === "lose") {
      interval = setInterval(() => {
        setTicker((ticker) => ticker + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [props.type, ticker]);

  function timeToGo(d: Date) {
    function z(n: number) {
      return (n < 10 ? "0" : "") + n;
    }
    var diff = d.getTime() - new Date().getTime();
    var sign = diff < 0 ? "-" : "";
    diff = Math.abs(diff);
    var hours = (diff / 3.6e6) | 0;
    var mins = ((diff % 3.6e6) / 6e4) | 0;
    var secs = Math.round((diff % 6e4) / 1e3);
    return sign + z(hours) + ":" + z(mins) + ":" + z(secs);
  }

  const calculatePuzzleLayout = (): (boolean | undefined)[][] => {
    const pllString = localStorage.getItem("processedLettersList");
    const cslString = localStorage.getItem("currentlySelectedLetters");
    const puzzleLayout: (boolean | undefined)[][] = [];
    if (pllString && cslString) {
      const pll: Letter[][] = JSON.parse(pllString);
      const csl: Letter[] = JSON.parse(cslString);
      let row: (boolean | undefined)[] = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];
      let offset = 0;
      for (let col = 0; col < 6; col++) {
        offset = pll[col].findIndex(
          (letter) => letter.letter === csl[col].letter
        );
        row = [undefined, undefined, undefined, undefined, undefined];
        for (let l = 0; l < pll[col].length; l++) {
          row[2 - offset] = pll[col][l].isUsed;
          offset--;
        }
        puzzleLayout.push(row);
      }
    }
    return puzzleLayout;
  };

  const getModalContent = (): ReactElement => {
    switch (props.type) {
      case "win":
        return (
          <>
            <h1>Great Job!</h1>
            {getStats()}
            <div className="win-modal-bottom-container">
              <div>
                <h4>{`Next Tileshift`}</h4>
                <p className="time-till-next">
                  {timeToGo(
                    new Date(moment().add(1, "days").toDate().toDateString())
                  )}
                </p>
              </div>
              <CopyToClipboard text={clipboardText}>
                <button
                  className={
                    "win-modal-share-button" + (copied ? " copied" : "")
                  }
                  onClick={() => {
                    props.setNotificationVisible(true);
                    setCopied(true);
                  }}
                >
                  {"SHARE"}
                </button>
              </CopyToClipboard>
            </div>
          </>
        );
      case "lose":
        return (
          <>
            <h1>Out of Moves</h1>
            {getStats()}
            <div className="win-modal-bottom-container">
              <div>
                <h4>{`Next Tileshift`}</h4>
                <p className="time-till-next">
                  {timeToGo(
                    new Date(moment().add(1, "days").toDate().toDateString())
                  )}
                </p>
              </div>
              <CopyToClipboard text={clipboardText}>
                <button
                  className={
                    "win-modal-share-button" + (copied ? " copied" : "")
                  }
                  onClick={() => {
                    props.setNotificationVisible(true);
                    setCopied(true);
                    calculatePuzzleLayout();
                  }}
                >
                  {"SHARE"}
                </button>
              </CopyToClipboard>
            </div>
          </>
        );
      case "help":
        return (
          <>
            <h1>How to Play</h1>
            <div className="modal-help-column-container">
              <div className="modal-help-column">
                <p className="modal-help-column-num">1</p>
                <img
                  src={require("../../assets/selected-word-tileshift.png")}
                  alt="selected-word-tileshift"
                  className="modal-help-column-img"
                />
                <p className="modal-help-desc">
                  Align the tiles to spell a word. Selected tiles are blue.
                </p>
              </div>
              <div className="modal-help-column">
                <p className="modal-help-column-num">2</p>
                <img
                  src={require("../../assets/completed-word-tileshift.png")}
                  alt="completed-word-tileshift"
                  className="modal-help-column-img"
                />
                <p className="modal-help-desc">
                  Tiles that have been used in a word turn green.
                </p>
              </div>
              <div className="modal-help-column">
                <p className="modal-help-column-num">3</p>
                <img
                  src={require("../../assets/fully-completed-tileshift-v2.png")}
                  alt="fully-completed-tileshift"
                  className="modal-help-column-img"
                />
                <p className="modal-help-desc">
                  Use all the tiles within thirty moves to win the game.
                </p>
              </div>
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <div
      className={"modal-background" + (props.show ? " active" : "")}
      onClick={
        props.type === "help" || props.type === "win" || props.type === "lose"
          ? () => props.setShow(false)
          : undefined
      }
    >
      <div
        className={
          "modal-content" +
          (props.loading ? " loading" : "") +
          (props.type === "help" ? " help" : "") +
          (props.type === "win" || props.type === "lose" ? " win" : "")
        }
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          className="modal-close-icon"
          onClick={() => {
            props.setShow(false);
          }}
        />
        {getModalContent()}
      </div>
    </div>
  );
};

export default CustomModal;
