import React, { useEffect, useState } from "react";
import { MainScreen } from "./screens/MainScreen/MainScreen";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import CustomModal from "./components/CustomModal/CustomModal";
import { CustomNotification } from "./components/CustomNotification/CustomNotification";
import moment from "moment";
import { analytics } from "./firebase";
import { ModalType } from "./types";

export const maxAllowedMoves = 30;

export const App = () => {
  const [retrieveFromLocalStorage, setRetrieveFromLocalStorage] =
    useState(false);
  const [show, setShow] = useState(false);
  const [modalType, setModalType] = useState<ModalType>("help");
  const [remainingMoves, setRemainingMoves] = useState<number>(maxAllowedMoves);
  const [gameIsActive, setGameIsActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(
    "Results copied to clipboard"
  );

  useEffect(() => {
    if (gameIsActive && remainingMoves < 1) {
      setGameIsActive(false);
      const formatedDate = moment().format("MM/DD/YYYY");
      const streaks: string[] = JSON.parse(
        localStorage.getItem("streaks") || "[]"
      );
      if (!streaks.includes(formatedDate)) {
        streaks.push(formatedDate);
      }
      localStorage.setItem("streaks", JSON.stringify(streaks));
      localStorage.setItem("remainingMoves", "0");
      setRemainingMoves(0);
      setModalType("lose");
      analytics.logEvent("lost game");
      setShow(true);
    }
  }, [gameIsActive, remainingMoves]);

  useEffect(() => {
    const remMoves = localStorage.getItem("remainingMoves");
    if (localStorage.length === 0) {
      setRemainingMoves(maxAllowedMoves);
      setModalType("help");
      setShow(true);
    } else if (remMoves && retrieveFromLocalStorage) {
      setRemainingMoves(parseInt(remMoves));
      setShow(false);
    } else if (remMoves && !retrieveFromLocalStorage) {
      setRemainingMoves(maxAllowedMoves);
      setShow(false);
    }
  }, [retrieveFromLocalStorage]);

  return (
    <div className="app-container">
      <Navbar
        setModalShow={setShow}
        setModalType={setModalType}
        remainingMoves={remainingMoves}
        loading={loading}
      />
      <MainScreen
        setModalShow={setShow}
        setModalType={setModalType}
        setGameIsActive={setGameIsActive}
        gameIsActive={gameIsActive}
        retrieveFromLocalStorage={retrieveFromLocalStorage}
        setRetrieveFromLocalStorage={setRetrieveFromLocalStorage}
        setLoading={setLoading}
        loading={loading}
        setRemainingMoves={setRemainingMoves}
        remainingMoves={remainingMoves}
      />
      <CustomModal
        show={show}
        setShow={setShow}
        type={modalType}
        setGameIsActive={setGameIsActive}
        loading={loading}
        setModalType={setModalType}
        setNotificationVisible={setNotificationVisible}
        setNotificationMessage={setNotificationMessage}
        remainingMoves={remainingMoves}
      />
      <CustomNotification
        visible={notificationVisible}
        message={notificationMessage}
        setVisible={setNotificationVisible}
      />
    </div>
  );
};
