import React, { ReactElement } from "react";
import "./Navbar.css";
import { ReactComponent as HelpIcon } from "../../assets/help.svg";
import { ModalType } from "../../types";
import useWindowDimensions from "../../utils/useWindowDimensions";

type NavbarProps = {
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  remainingMoves: number;
  loading: boolean;
};

function Navbar(props: NavbarProps): ReactElement<NavbarProps> {
  const width = useWindowDimensions().width;

  return (
    <div className="navbar">
      <div
        className={"navbar-moves-container" + (props.loading ? " loading" : "")}
      >
        <p className="navbar-rem-text">{"Remaining moves"}</p>
        <p
          className={
            "navbar-rem-moves" + (props.remainingMoves <= 5 ? " warning" : "")
          }
        >{`${props.remainingMoves}`}</p>
      </div>
      {width > 700 ? (
        <h1 className="navbar-logo-text">Tileshift</h1>
      ) : (
        <img
          className="navbar-logo-icon"
          src={require("../../assets/tileshift-logo.png")}
        />
      )}
      <HelpIcon
        className="navbar-help-icon"
        onClick={() => {
          props.setModalType("help");
          props.setModalShow(true);
        }}
      />
    </div>
  );
}

export default Navbar;
