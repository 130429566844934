import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { maxAllowedMoves } from "../../App";
import LettersRoller from "../../components/LettersRoller/LettersRoller";
import {
  parseCSLfromLocalStorage,
  parsePLLfromLocalStorage,
} from "../../utils/parseFromJSON";
import "./MainScreen.css";
import moment from "moment";
import { checkList } from "../../data/CheckList";
import { Letter, ModalType } from "../../types";
import { analytics, auth } from "../../firebase";
import {
  deleteOutDatedPuzzles,
  getFirebaseWordData,
} from "../../utils/firebaseUtils";

type MainScreenProps = {
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<ModalType>>;
  setGameIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setRetrieveFromLocalStorage: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRemainingMoves: React.Dispatch<React.SetStateAction<number>>;
  retrieveFromLocalStorage: boolean;
  gameIsActive: boolean;
  loading: boolean;
  remainingMoves: number;
};

export const MainScreen = (
  props: MainScreenProps
): ReactElement<MainScreenProps> => {
  const [processedLettersList, setProcessedLettersList] = useState<
    Letter[][] | undefined
  >(undefined);

  const [currentlySelectedLetters, setCurrentlySelectedLetters] = useState<
    Letter[]
  >([]);

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // sign in anonymously
    auth
      .signInAnonymously()
      .then(() => {
        analytics.logEvent("visited site");
        const lastOpened = localStorage.getItem("lastOpened");
        const streaks: string[] = JSON.parse(
          localStorage.getItem("streaks") || "[]"
        );
        const currentFormatedDate = moment().format("MM-DD-YYYY");
        let tempRFLS: boolean = props.retrieveFromLocalStorage;
        if (lastOpened && lastOpened === currentFormatedDate) {
          props.setRetrieveFromLocalStorage(true);
          tempRFLS = true;
        } else {
          localStorage.setItem("remainingMoves", maxAllowedMoves.toString());
        }
        if (
          !streaks.includes(moment().subtract(1, "days").format("MM/DD/YYYY"))
        ) {
          localStorage.setItem("streaks", "[]");
        }
        localStorage.setItem("lastOpened", currentFormatedDate);
        if (!processedLettersList || processedLettersList.length === 0) {
          props.setLoading(true);
          if (tempRFLS) {
            setWordDataFromLocalStorage();
            props.setLoading(false);
          } else {
            getFirebaseWordData(currentFormatedDate)
              .then((wordData) => {
                setProcessedLettersList(wordData.pll);
                setCurrentlySelectedLetters(wordData.csl);
                deleteOutDatedPuzzles().then(() => {
                  props.setLoading(false);
                });
              })
              .catch((err) => {
                console.log("Something went wrong: ", err);
                props.setLoading(false);
              });
          }
        }
      })
      .catch(console.error);
  }, []);

  const setWordDataFromLocalStorage = () => {
    const pllFromLocalStorage = parsePLLfromLocalStorage();
    const cslFromLocalStorage = parseCSLfromLocalStorage();
    if (pllFromLocalStorage && pllFromLocalStorage.length !== 0) {
      setProcessedLettersList(pllFromLocalStorage);
    }
    if (cslFromLocalStorage && cslFromLocalStorage.length !== 0) {
      setCurrentlySelectedLetters(cslFromLocalStorage);
    }
  };

  const changedCurrSelectedLetters = (letters: Letter[]) => {
    if (processedLettersList) {
      const tempProcessedLettersList = processedLettersList;
      if (checkList.includes(convertLettersToString(letters))) {
        for (let i = 0; i < letters.length; i++) {
          for (let j = 0; j < tempProcessedLettersList[i].length; j++) {
            if (tempProcessedLettersList[i][j].letter === letters[i].letter) {
              tempProcessedLettersList[i][j].isUsed = true;
            }
          }
          letters[i].isUsed = true;
        }
      }
      setProcessedLettersList(tempProcessedLettersList);
      localStorage.setItem(
        "processedLettersList",
        JSON.stringify(tempProcessedLettersList)
      );
      localStorage.setItem("currentlySelectedLetters", JSON.stringify(letters));
      checkIfAllLettersUsed();
      forceUpdate();
    }
  };

  const checkIfAllLettersUsed = () => {
    if (processedLettersList) {
      for (let i = 0; i < processedLettersList.length; i++) {
        for (let j = 0; j < processedLettersList[i].length; j++) {
          if (!processedLettersList[i][j].isUsed) {
            return;
          }
        }
      }
      props.setGameIsActive(false);
      props.setModalType("win");
      const formatedDate = moment().format("MM/DD/YYYY");
      const gamesWon: string[] = JSON.parse(
        localStorage.getItem("gamesWon") || "[]"
      );
      const streaks: string[] = JSON.parse(
        localStorage.getItem("streaks") || "[]"
      );
      if (!gamesWon.includes(formatedDate)) {
        gamesWon.push(formatedDate);
      }
      if (!streaks.includes(formatedDate)) {
        streaks.push(formatedDate);
      }
      localStorage.setItem("gamesWon", JSON.stringify(gamesWon));
      localStorage.setItem("streaks", JSON.stringify(streaks));
      analytics.logEvent("won game");
      setTimeout(() => {
        props.setModalShow(true);
      }, 500);
    }
  };

  const convertLettersToString = (letters: Letter[]) => {
    return letters.map((letter) => letter.letter).join("");
  };

  return (
    <>
      <div className={"main-screen" + (props.loading ? " loading" : "")}>
        <div className="current-word-container">
          {currentlySelectedLetters.map((letter, index) => (
            <div className="current-word-letter" key={index}>
              {letter.letter.toUpperCase()}
            </div>
          ))}
        </div>
        <div className="main-screen-game-container">
          <div className="ms-column-padding"></div>
          <div className="letters-roller-container">
            {processedLettersList &&
              processedLettersList.map((letters, index) => {
                return (
                  <LettersRoller
                    letters={letters}
                    key={index}
                    index={index}
                    currentlySelectedLetters={currentlySelectedLetters}
                    changedCurrSelectedLetters={changedCurrSelectedLetters}
                    gameIsActive={props.gameIsActive}
                    retrieveFromLocalStorage={props.retrieveFromLocalStorage}
                    setRemainingMoves={props.setRemainingMoves}
                    remainingMoves={props.remainingMoves}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
